import { Row, Col, Image, Form, Input, Button, InputNumber, Select, Progress, Card, Table, Space, Modal, Upload,Tooltip, Radio, Popconfirm, Checkbox, Spin } from 'antd';
import constants from '../../constants/constants';
// import { SaveOutlined, UploadOutlined, CloseOutlined, InfoCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { DeleteOutlined, SaveOutlined, CheckOutlined, CheckCircleTwoTone, EditOutlined, UserOutlined, InfoCircleOutlined, TrophyOutlined, CloseOutlined } from '@ant-design/icons';
import SignaturePad from 'react-signature-canvas';
import GaugeChart from 'react-gauge-chart';
import { COOKIE, getCookie } from '../../services/cookie';
import ReactHlsPlayer from 'react-hls-player';

const loginType = getCookie(COOKIE.LoginType);
const { TextArea } = Input;

const chartStyle = {
	height: 250,
	width: 540,
};

//templete to add titles.
export const template0 = (title, option_message, scale_msg) => {
	let value = null;
	if (title) {
		value = title;
	} else if (option_message) {
		value = option_message;
	} else if (scale_msg) {
		value = scale_msg;
	}
	return (
		<div>
			<div className='font-bold mb-3 text-center' dangerouslySetInnerHTML={{ __html: value }}/>
		</div>
	);
};

//templete to add Images.
export const template1 = (src, type) => {
	if (type === "I") {
		return (
			<div className="grid place-content-center place-items-center py-3.5">
				<Image
					alt="img"
					preview={false}
					src={src}
				/>
			</div>
		);
	} else {
		return (
			<div className="grid place-content-center place-items-center py-3.5">
				<Image
					className="max-w-5xl"
					src={src}
					preview={{ src }}
				/>
			</div>
		);
	}
};

//templete to add data.
export const template2 = (title, data, option_message, scale_msg) => {
	if (option_message) {
		return <div className="font-semibold" dangerouslySetInnerHTML={{ __html: option_message }}/>;
	} else if (scale_msg) {
		return <div className="font-semibold" dangerouslySetInnerHTML={{ __html: scale_msg }}/>;
	}
	else {
		return (
			<div>
				<div className='font-semibold mb-3 text-center' dangerouslySetInnerHTML={{ __html: title }}/>
				<div dangerouslySetInnerHTML={{ __html: data }}/>
			</div>
		);
	}
};

//template for textarea with label
export const template3 = (question, type_left, onChange, formName, tempData, isDisable, tooltipValue, placeholder, section_res, isCheckboxDisable, onCheckboxChange, isChecked) => {
	const rowValue = type_left === "TA" ? 8 : 1;
	return (
		<div className='py-2 labelInput'>
			<Tooltip title={tooltipValue} placement="bottom" trigger={['focus']}>
				<Form.Item
					name={formName}
					className="mb-0"
					label={
						question ? (
							<div className='flex flex-col'>
								{(section_res && loginType !== "0") ? (
									<Checkbox
										defaultChecked={isChecked}
										onChange={e => onCheckboxChange(e, tempData)}
										disabled={isCheckboxDisable}
									>
										<div
											dangerouslySetInnerHTML={{ __html: question }}
											className='text-start whitespace-normal self-end mb-2'
										/>
									</Checkbox>
								) : (
									<div
										dangerouslySetInnerHTML={{ __html: question }}
										className='text-start whitespace-normal self-end mb-2'
									/>
								)}
							</div>
						) : null
					}
				>
					<TextArea
						onChange={(e) => onChange(e.target.value, tempData, "text")}
						className={`w-full ${isDisable ? "bg-gray-200" : ""}`}
						autoSize={type_left !== "TA"}
						rows={rowValue}
						readOnly={isDisable}
						status={tempData?.is_edited === 1 ? "error" : null}
						placeholder={placeholder}
					/>
				</Form.Item>
			</Tooltip>
		</div>
	);
};

//template to display normal image and to preview image
export const template4 = (src, contentright, type) => {
	return (
		<div>
			<Row gutter={[24, 16]} className="py-3.5">
				<Col xs={24} lg={8} className="text-center">
					{type === "I" ? <Image
						className='border-2 rounded-md border-gray-300 bg-white overflow-hidden mt-1.5'
						alt="img"
						preview={false}
						src={src}
					/> : <Image
						width={400}
						height={300}
						className='mt-1.5'
						src={src}
						preview={{ src: src }}
					/>}
				</Col>
				<Col xs={24} lg={16}>
					<div dangerouslySetInnerHTML={{ __html: contentright }}/>
				</Col>
			</Row>
		</div>
	);
};

//template with chaeckbox and textarea
export const template5 = (label, data, disable,onChangeCheckboxValue,onchangeText) => {
	return (
		<Col span={24}>
			<Form.Item
				name="template5">
				<Row gutter={[8, 8]}>
					<Col xs={24} lg={6}>
						<Checkbox
							disabled={disable}
							checked={data.check_box === 'f' ? false : true}
							onChange={e => onChangeCheckboxValue(e,data,data?.template_id)}>
							{<div dangerouslySetInnerHTML={{ __html: label }} />}
						</Checkbox>
					</Col>
					<Col xs={24} lg={18}>
						<TextArea
							status={data?.is_edited ? "error" : null}
							readOnly={disable}
							autoSize={true}
							rows={1}
							value={data.client_ans}
							disabled={data.check_box === "t" ? false : true}
							onChange={e => onchangeText(e,data,data?.template_id)} />
					</Col>
				</Row>
			</Form.Item>
		</Col>
	);
};

//template to display video link with image.
export const template6 = (contentLeft, title,url) => {
	return (
		<div className='text-center py-2'>
			{contentLeft !== "" && contentLeft !== null && (
				<a href={contentLeft} target="_blank" rel="noreferrer">
					<Image
						alt="Video thumbnail"
						preview={false}
						src={url}
					/>
				</a>
			)}
			<div>
				<Button type="link" className='text-lg font-semibold' href={contentLeft} target="_blank">{title}</Button>
			</div>
		</div>
	);
};
//template for radio group
export const template7 = (contentLeft, data, disable,onChange) => {
	let divclass = 'w-1/5 text-center p-3 border-black border-x-2 border-t-2';
	if (data?.is_edited) {
		divclass = 'w-1/5 text-center p-3 border-black border-x-2 border-t-2 flex';
	}
	return (
		<div className='flex table-bordered'>
			<div className='w-4/5 p-3 border-black border-l-2 border-t-2 font-semibold'>{contentLeft}</div>
			<div className={divclass}>
				{data?.is_edited ? <div className="mr-4 mt-1"><EditOutlined /></div> : null}
				<Form.Item
					name={data?.item_id}
					className="mb-0"
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Radio.Group
						disabled={disable}
						onChange={(e)=>onChange(e.target.value,data,"radio")}
					>
						<Radio id="yes" value={"1"}>Yes</Radio>
						<Radio id="no" value={"2"}>No</Radio>
					</Radio.Group>
				</Form.Item>
			</div>
		</div>
	);
};

//template to required form with select dropdown
export const template8 = (contentLeft, data, disable, onchangeSelect) => {
	let cssClass = null;
	if (data?.is_edited) {
		cssClass = 'w-full disabled-outline';
	} else {
		cssClass = 'w-full';
	}
	return (
		<div className='flex table-bordered w-6/12 mx-auto'>
			<div className='w-3/5 p-3 border-black border-l-2 border-t-2'>{contentLeft}</div>
			<div className='w-2/5 p-3 border-black border-x-2 border-t-2'>
				<Form.Item
					name={data?.item_id}
					className="mb-0"
					rules={[
						{
							required:  true,
							message: constants.RequiredField,
						},
					]}
				>
					<Select
						status={data?.is_edited ? "error" : null}
						disabled={disable}
						showSearch
						onChange={(e)=>onchangeSelect(e,data,"radio")}
						className={`disabled-select-dark ${cssClass}`}
						optionFilterProp="children"
						filterOption={(input, option) => {
							return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.label.toLowerCase().indexOf(input.toLowerCase()) < 1;
						}}
						options={[
							{
								value: "1",
								label: 'Never',
							},
							{
								value: "2",
								label: 'Once in a great while',
							},
							{
								value: "3",
								label: 'Rarely',
							},
							{
								value: "4",
								label: 'Sometimes',
							},
							{
								value: "5",
								label: 'Often',
							},
							{
								value: "6",
								label: 'Usually',
							},
							{
								value: "7",
								label: 'Always',
							},
						]}
					/>
				</Form.Item>
			</div>
		</div>
	);
};

//template to display GaugeChart.
export const template9 = (persent) => {
	let val = persent / 7;
	return (
		<div className="text-center">
			<div className='flex justify-center items-center stress-chart'>
				<GaugeChart
					id="gauge-chart2"
					style={chartStyle}
					nrOfLevels={7}
					percent={val}
					formatTextValue={_value => persent}
				/>
			</div>
		</div>
	);
};

//template to display score in progress component.
export const template10 = (content_left) => {
	return (
		<div className="text-center">
			<div className="font-semibold mb-3">What is your score?</div>
			<Progress type="circle" percent={content_left}/>
		</div>
	);
};

//template to display two images on left side and right side.
export const template11 = (contentleft, contentright, typeleft, typeright) => {
	return (
		<Row gutter={[8, 8]} className="py-3.5">
			<Col xs={24} lg={12} className="grid place-content-center place-items-center">
				{typeleft === "I" ? <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					alt="img"
					src={contentleft}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					src={contentleft}
					preview={{ src: contentleft }}
				/>}
			</Col>
			<Col xs={24} lg={12} className="grid place-content-center place-items-center">
				{typeright === "I" ? <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					alt="img"
					src={contentright}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					src={contentright}
					preview={{ src: contentright }}
				/>}
			</Col>
		</Row>
	);
};

// Label with input & textarea field inside a Card.
export const template12 = (content_left) => {
	return (
		<div className='grid place-content-center place-items-center'>
			<Card className='border-2 border-black p-3 my-4 w-9/12'>
				<div dangerouslySetInnerHTML={{ __html: content_left }}/>
			</Card>
		</div>
	);
};

export const template13 = (content_left, data, disable,onChangeCheckboxValue) => {
	return (
		<div className='py-2'>
			{data?.is_edited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
			<Form.Item
				name={data?.item_id}
				valuePropName="checked"
				className="mb-0"
			>
				<Checkbox
					disabled={disable}
					onChange={e => onChangeCheckboxValue(e.target.checked === true ? "1":"0", data, "radio")}
				>{<div dangerouslySetInnerHTML={{ __html: content_left }} />}</Checkbox>
			</Form.Item>
		</div>
	);
};

//table code
export const template15 = (values, disable, tableOneColumns, tableComponents, tableOneDataSource, handleChange, handleAdd, handleActionSave, handleFutureChange) => {
	const sortOptionsAlphabetically = (FutureOptions) => {
		return FutureOptions.sort((a, b) => {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		});
	};
	const FutureOptions = [
		{ label: "Why am I angry?", value: "Why am I angry?" },
		{ label: "Taking out the heat", value: "Taking out the heat" },
		{ label: "Time out", value: "Time out" },
		{ label: "Distraction", value: "Distraction" },
		{ label: "Silly humor", value: "Silly humor" },
		{ label: "Self-talk and good thinking", value: "Self-talk and good thinking" },
		{ label: "Assertiveness and practice", value: "Assertiveness and practice" },
		{ label: "Identify Triggers", value: "Identify Triggers" },
		{ label: "Consider Whether Your Anger Is Helpful or Unhelpful", value: "Consider Whether Your Anger Is Helpful or Unhelpful" },
		{ label: "Recognize Your Warning Signs", value: "Recognize Your Warning Signs" },
		{ label: "Step Away From the Triggering Situation", value: "Step Away From the Triggering Situation" },
		{ label: "Talk Through Your Feelings", value: "Talk Through Your Feelings" },
		{ label: "Get in a Quick Workout", value: "Get in a Quick Workout" },
		{ label: "Focus on the Facts", value: "Focus on the Facts" },
		{ label: "Distract Yourself With a New Activity", value: "Distract Yourself With a New Activity" },
		{ label: "Acknowledge Your Underlying Emotion", value: "Acknowledge Your Underlying Emotion" },
		{ label: "Avoid Suppressing Your Anger", value: "Avoid Suppressing Your Anger" },
		{ label: 'Create a "Calm-Down" Kit', value: 'Create a "Calm-Down" Kit' },
		{ label: "Get Advice", value: "Get Advice" },
		{ label: "Check yourself", value: "Check yourself" },
		{ label: "Don’t dwell", value: "Don’t dwell" },
		{ label: "Change the way you think", value: "Change the way you think" },
		{ label: 'Avoid words like "never" or "always"', value: 'Avoid words like "never" or "always"' },
		{ label: "Translate expectations into desires", value: "Translate expectations into desires" },
		{ label: "Focused breathing", value: "Focused breathing" },
		{ label: "Get active", value: "Get active" },
		{ label: "Regular physical exercise", value: "Regular physical exercise" },
		{ label: "Recognize (and avoid) your triggers", value: "Recognize (and avoid) your triggers" },
		{ label: "Think before you speak", value: "Think before you speak" },
		{ label: "Once you're calm, express your concerns", value: "Once you're calm, express your concerns" },
		{ label: "Take a timeout", value: "Take a timeout" },
		{ label: "Identify possible solutions", value: "Identify possible solutions" },
		{ label: "Stick with 'I' statements", value: "Stick with 'I' statements" },
		{ label: "Don't hold a grudge", value: "Don't hold a grudge" },
		{ label: "Use humor to release tension", value: "Use humor to release tension" },
		{ label: "Practice relaxation skills", value: "Practice relaxation skills" },
		{ label: "Know when to seek help", value: "Know when to seek help" },
	];
	const column = tableOneColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				tableName: values.content_left === "My List Table" ? "Mylist" : "Mylist2",
				handleAdd,
				disable: disable,
			}),
		};
	});
	const tableTwoColumns = [
		// section 4 table starts here
		{
			title: 'Name',
			dataIndex: 'name',
			width: '10%',
			editable: true,
			hidden: values.content_left === "Anger Sheet table 2" ? true : false,
		},
		{
			title: "What they did to me?",
			dataIndex: "problem",
			editable: true,
			width: "20%",
			hidden: values.content_left === "Anger Sheet table 2" ? true : false,
		},
		// section 5 table starts here
		{
			title: "Who Or what do i fear?",
			dataIndex: "name",
			editable: true,
			width: "20%",
			hidden: values.content_left === "Anger Sheet table" ? true : false,
		},
		{
			title: "Why do i have this fear?",
			dataIndex: "fearCause",
			editable: true,
			width: "20%",
			hidden: values.content_left === "Anger Sheet table" ? true : false,
		},
		// common column
		{
			title: "Which Instinct was affected?",
			dataIndex: "affected",
			width: "20%",
			// hidden: values.content_left === "Anger Sheet table 2" ? true : false,
			render: (_, record) => {
				return <Select
					disabled={disable}
					defaultValue={record.affected ? record.affected : []}
					onChange={(e) => handleChange(e, record)}
					mode="multiple"
					allowClear
					className="w-full"
					options={[
						{
							label: "Sex",
							options: [
								{
									label: "Connections",
									value: "Connections"
								},
								{
									label: "Relationships",
									value: "Relationships"
								}
							]
						},
						{
							label: "Social",
							options: [
								{
									label: "Fame",
									value: "Fame"
								},
								{
									label: "Popularity",
									value: "Popularity"
								},
								{
									label: "Self-esteem",
									value: "Self-esteem"
								}
							]
						},
						{
							label: "Survival",
							options: [
								{
									label: "Jobs",
									value: "Jobs"
								},
								{
									label: "Money",
									value: "Money"
								},
								{
									label: "Power",
									value: "Power"
								}
							]
						}
					]}
				/>;
			}
		},
		// section 4
		{
			title: "How did I react?",
			dataIndex: "reaction",
			editable: true,
			width: "20%",
			hidden: values.content_left === "Anger Sheet table 2" ? true : false,
		},
		// section 4 table ends here
		// section 5
		{
			title: "Are There any actions i took to cause Or cover the fear?",
			dataIndex: "fearAction",
			editable: true,
			width: "20%",
			hidden: values.content_left === "Anger Sheet table" ? true : false,
		},
		// section 5 table ends here
		// common column
		{
			title: "What can i do?",
			dataIndex: "futureAction",
			width: "22%",
			render: (_, record) => {
				return <Select
					disabled={disable}
					defaultValue={record.futureAction ? record.futureAction : []}
					onChange={(e) => handleFutureChange(e, record)}
					mode="multiple"
					allowClear
					className="w-full"
					options={sortOptionsAlphabetically(FutureOptions)}
				/>;
			}
		},
		{
			title: 'Action',
			dataIndex: 'action',
			align: "center",
			width: '9%',
			// hidden:values.content_left === "Anger Sheet table 2"?true:false,
			render: (_, record) => {
				return <Space>
					<Tooltip title={constants.Save}>
						<Button type="link" disabled={disable} onClick={() => { handleActionSave(record,values); }} icon={<SaveOutlined />} /></Tooltip>
					{tableOneDataSource.length > 1 && (
						<Tooltip title={constants.Delete}>
							<Popconfirm disabled={disable} title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
								<Button type="link" danger icon={<DeleteOutlined />} />
							</Popconfirm></Tooltip >
					)}
				</Space>;
			}
		},
	].filter(item => !item.hidden);
	const tableTwoColumnsNoDrodown = [
		// section 6 table column starts here
		{
				title: 'My Flaws',
				dataIndex: 'flaws',
				render: (record) => {
					return (<span>{record}</span>
					);
				}
			},
		{
			title: 'People or Institution we have hurt by our actions arising out of character flaws',
			dataIndex: 'name',
			editable: true,
		},
		{
			title: "What did we do or say to them?",
			dataIndex: "problem",
			editable: true,
		},
		{
			title: "How can we make this right?",
			dataIndex: "affected",
			editable: true,
		},
		{
			title: 'Action',
			dataIndex: 'action',
			align: "center",
			render: (_, record) => {
				return <Space>
					<Tooltip title={constants.Save}>
						<Button type="link" disabled={disable} onClick={() => { handleActionSave(record); }} icon={<SaveOutlined />} /></Tooltip>
					{tableOneDataSource.length > 1 && (
						<Tooltip title={constants.Delete}>
							<Popconfirm disabled={disable} title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
								<Button type="link" danger icon={<DeleteOutlined />} />
							</Popconfirm></Tooltip >
					)}
				</Space>;
			}
		},
	];
	const column2 = tableTwoColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				tableName: values.content_left === "Anger Sheet table" ? "AngerSheetTable" : "AngerSheetTable2",
				disable: disable,
				handleAdd
			}),
		};
	});
	const column3 = tableTwoColumnsNoDrodown.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				tableName: "tableCode",
				disable: disable,
				handleAdd
			}),
		};
	});
	return (
			<div>
				{
					values.content_left === "Anger Sheet table" || values.content_left === "Anger Sheet table 2" || values.content_left === "table code" ?
						<Table
							scroll={{ x: 900 }}
							components={tableComponents}
							rowClassName={() => 'editable-row'}
							bordered
							dataSource={/* values?.client_ans!==null ? values?.client_ans: */tableOneDataSource}
							columns={values.content_left === "table code" ? column3 : column2}
							pagination={false}
							title={() => values?.is_edited ? <CheckCircleTwoTone /> : null}
						/> 
						: 
						<div className="w-2/5 mx-auto">
							<Table
								components={tableComponents}
								rowClassName={() => 'editable-row'}
								bordered
								dataSource={/* values?.client_ans !== null ? values?.client_ans : */ tableOneDataSource}
								columns={column}
								pagination={false}
								title={() => values?.is_edited ? <CheckCircleTwoTone /> : null}
							/>
						</div>
				}
			</div>
	);
};

// Table with header & sub header having checkboxes in each column.
export const template18 = (values, disable,onChangeCheckboxTable,checkedValue) => {
	const column = [
		{
			title: 'My List',
			dataIndex: 'mylist',
			width: '50%',
		},
	];
	let datasource = [];
	if (values.content_left === "table checkbox value") {
		if (values.client_ans) {
			if (values.client_ans.length > 0) {
				datasource.length = 0;
				for (let i = 0; i < values.client_ans.length; i++) {
					datasource.push({ mylist: values.client_ans[i] });
				}
			}
		}
	}
	//To show or hide Spiritual column.
	const includedOptions = values?.client_ans?.filter(answer =>
		constants.SpiritualOptions?.some(option => option.value === answer)
	);
	return (
		<>
			{values.content_left === "table with checkboxes code?" ?
				<div className='overflow-x-auto'>
					<Checkbox.Group disabled={disable} onChange={onChangeCheckboxTable} value={checkedValue}>
						<table className="w-full blue-header">
							{values?.is_edited ? <caption className="text-left"><CheckCircleTwoTone /></caption> : null}
							<thead>
								<tr>
									<th>General</th>
									{
										includedOptions?.length > 0 ?
											<th>Spiritual</th> :
											null
									}
									<th>Buddhism</th>
									<th>Christianity</th>
									<th>Hinduism</th>
									<th>Sikhism</th>
									<th>Islam</th>
								</tr>
								<tr>
									<th>The greatest defects of humans are</th>
									{
										includedOptions?.length > 0 ?
											<th>Social Evils</th> :
											null
									}
									<th>Obscurations</th>
									<th>Sins</th>
									<th>Skhandas</th>
									<th>Enemies of Man</th>
									<th>Nafs</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Space direction="vertical">
											{constants.GeneralOptions.map((option) => {
												return <Tooltip title={option.tooltip} color='#075985'><div className='flex items-center'>
														<Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
													</div></Tooltip>
											})}
										</Space>
									</td>
									{
										includedOptions?.length > 0 ?
											<td>
												<Space direction="vertical">
													{constants.SpiritualOptions.map((option) => {
														return <Tooltip title={option.tooltip} color='#075985'>
															<div className='flex items-center'>
																<Checkbox key={option.value} value={option.value}>{option.label}
																</Checkbox>
															</div>
														</Tooltip>
													})}
												</Space>
											</td> :
											null
									}
									<td>
										<Space direction="vertical">
											{constants.BuddhismOptions.map((option) => {
												return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
											})}
										</Space>
									</td>
									<td>
										<Space direction="vertical">
											{constants.ChristianityOptions.map((option) => {
												return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
											})}
										</Space>
									</td>
									<td>
										<Space direction="vertical">
											{constants.HinduismOptions.map((option) => {
												return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
											})}
										</Space>
									</td>
									<td>
										<Space direction="vertical">
											{constants.SikhismOptions.map((option) => {
												return  <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
											})}
										</Space>
									</td>
									<td>
										<Space direction="vertical">
											{constants.IslamOptions.map((option) => {
												return <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
											})}
										</Space>
									</td>
								</tr>
							</tbody>
						</table>
					</Checkbox.Group>
				</div> :
				<div className="w-2/5 mx-auto">
					<Table
						columns={column}
						dataSource={datasource}
						pagination={true}
					></Table>
				</div>}
		</>
	);
};

// Checkbox with input field below.
export const template19 = (content_left, data, disable,onChangeCheckboxValue,onchangeText) => {
	return (
		<div>
			<div>
				<Checkbox
					disabled={disable}
					checked={data.check_box === 'f' ? false : true}
					onChange={e => onChangeCheckboxValue(e,data,data?.template_id)}
				>
					<div dangerouslySetInnerHTML={{ __html: content_left }} />
				</Checkbox>
			</div>
			<div className='pl-6 mb-5'>
				<TextArea
					status={data?.is_edited ? "error" : null}
					readOnly={disable}
					autoSize={true}
					rows={1}
					value={data.client_ans}
					disabled={data.check_box === "t" ? false : true}
					onChange={e => onchangeText(e,data,data?.template_id)}
				/>
			</div>
		</div>
	);
};
//signature pad template.
export const template20 = (sigPad,clear,handleEnd) => {
	return (
		<Card title={<h3>Signature</h3>}>
			<div className="sigContainer">
				<SignaturePad canvasProps={{ className: 'sigPad' }}
					ref={sigPad}
					onEnd={handleEnd}
				/>
				<Tooltip title="Clear">
					<Button type="text" danger className="absolute bottom-0 right-0 m-4" onClick={clear} icon={<DeleteOutlined />} />
				</Tooltip>
			</div>
		</Card>
	);
};

//lusher color test
export const luscherColorTest = (boxes,handleBoxClick,item_id,testTaken,clientName,resetLusherColor,downloadResult) => {
	let spanValue = "";
	if(loginType === "0"){
		spanValue = "You have already taken the Color Personality Test.";
	}else{
		spanValue =`${clientName} has already taken the Color Personality Test.`;
	}
	return (
		<>
			<Row 
				gutter={[48, 48]} 
				className = "border-2 border-solid border-black p-8"
			>
				{testTaken !== null?
					<>
						<Space>
							<span className="font-bold">{spanValue}</span>
							{loginType === "1" && <div><Button className='bg-sky-800 text-white' onClick={resetLusherColor}>Reset</Button></div>}
							{loginType === "1" && <div><Button className='bg-sky-800 text-white' onClick={downloadResult}>Download</Button></div>}
						</Space>
					</>
					:	boxes.map((box) => (
						<Col xs={24} md={12} lg={6} key={box.id}>
							{loginType === "1" ? 
								<div 
									className="h-36 w-40 rounded-md cursor-pointer m-auto" 
									style={{ 
										backgroundColor: box.color,
										display: box.visible ? "block" : "none"
									}}
								>
								</div>:
								<div 
									className="h-36 w-40 rounded-md cursor-pointer m-auto" 
									style={{ 
										backgroundColor: box.color,
										display: box.visible ? "block" : "none"
									}}
									onClick={() => handleBoxClick(box.id,box.color,item_id)}
								>
								</div>
							}
						</Col>
					))}
			</Row>
		</>
	);
};

//checkbox grid
export const template24 = (content_left, data, disable, onChangeCheckboxValue) => {
	const className = data?.type_left === 'CB2' ? 'py-2 w-1/2 md:w-1/4 inline-block' : 'py-2 mr-5 inline-block';
	return (
		<div className={className}>
			{data?.is_edited ? <div className="mr-4"><CheckCircleTwoTone /></div> : null}
			<Form.Item
				name={data?.item_id}
				valuePropName="checked"
				className="mb-0"
			>
				<Checkbox
					disabled={disable}
					onChange={e => onChangeCheckboxValue(e.target.checked === true ? "1" : "0", data, "radio")}
				>
					<div dangerouslySetInnerHTML={{ __html: content_left }} />
				</Checkbox>
			</Form.Item>
		</div>
	);
};

//checkbox with image
export const template26 = (content_left, data, disable, onChangeCheckboxValue) => {
	return (
		<div className='py-2 inline-block w-1/4 checkbox-start'>
			<Form.Item
				name={data?.item_id}
				valuePropName="checked"
				className="mb-0"
			>
				<Checkbox
					disabled={disable}
					onChange={e => onChangeCheckboxValue(e.target.checked === true ? "1" : "0", data, "radio")}
				>
					<Image
						alt="img"
						className='!w-60'
						preview={false}
						src={content_left}
					/>
				</Checkbox>
			</Form.Item>
		</div>
	);
};

// YouTube video
export const templateYouTube = (contentLeft) => {
	return (
		<div className="flex justify-center my-5">
			<iframe width="560" height="315" src={contentLeft} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
		</div>
	)
};

// Video streaming template
export const templateVideoStreaming = (videoUrl) => {
	return (
		<div className='flex justify-center'>
			<ReactHlsPlayer
				src={videoUrl}
				autoPlay={false}
				controls={true}
				width="50%"
				height="auto"
				hlsConfig={{
					maxLoadingDelay: 4,
					minAutoBitrate: 0,
					lowLatencyMode: true,
				}}
			/> 
		</div>
	)
};